import React, { useCallback } from 'react'
import { Dialog, DialogContent, IconButton, Stack, useMediaQuery, useTheme } from "@mui/material"
import { QrReader } from "react-qr-reader"

import {
  Close as CloseIcon
} from '@mui/icons-material'

import { defaultFunction } from "../../utilities/utilities"

import { useTranslation } from 'react-i18next'
import { DEFAUTL_QR_START_CODE } from "../../../variables"

function QRDialog(props) {

  const {
    open = false,
    onClose = defaultFunction,
    onChangeUniqueIdentificator = defaultFunction,
    onChangeIdentification = defaultFunction,
    message
  } = props

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  const onQRResult = useCallback((result, _) => {
    if (!!result) {
      const { text = '' } = result
      if (text.startsWith(DEFAUTL_QR_START_CODE)) {
        onChangeUniqueIdentificator(text)
      } else {
        onChangeIdentification(text)
      }
    }
  }, [onChangeIdentification, onChangeUniqueIdentificator])

  return (
    <Dialog
      className='visitor-dialog'
      fullScreen={!isLarge}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm">
      <DialogContent>
        <Stack gap={4}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <h1>{t("Scan QR code")}</h1>
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} alignItems="center">
            <div style={{
              width: '399px',
              background: 'black',
              border: 'solid 8px var(--primary)'
            }}>
              <QrReader
                onResult={onQRResult}
                style={{ width: '100%', height: '100%', }}
              />
            </div>
            <h2>{t('Place the QR in the white rectangle.')}</h2>
          </Stack>
          {message}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}


export default QRDialog
import React from 'react';
import {
  Dialog,
  IconButton,
  Typography,
  Box,
  Button,
  Stack,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { defaultFunction } from '../../utilities/utilities';

function InvitationNotesDialog({ 
  open, 
  onClose, 
  notes, 
  hostName,
  setNoteIsAlreadyRead = defaultFunction
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          width: '328px',
          margin: 0,
          position: 'absolute',
          top: '62px',
          left: '544px',
          borderRadius: '16px',
          padding: '16px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'visible',
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '18px',
            left: '-7px',
            width: '16px',
            height: '16px',
            backgroundColor: 'inherit',
            transform: 'rotate(45deg)',
            zIndex: 0,
          },
        }
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'transparent'
        }
      }}
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'visible',
        }
      }}
    >
      <Stack 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          mb: 1.5,
          position: 'relative',
          width: '100%',
          zIndex: 1,
        }}
      >
        <InfoOutlinedIcon 
          sx={{ 
            color: '#1D8099', 
            mr: 1,
            fontSize: '56px'
          }} 
        />
        <Typography 
          sx={{ 
            fontSize: '20px',
            fontWeight: 700,
            color: '#1D8099',
            flex: 1
          }}
        >
          {t('Host notes')}
        </Typography>
        <IconButton 
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: -8,
            top: -8,
            color: '#6B7280',
            padding: '8px',
          }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      </Stack>

      <Typography 
        sx={{ 
          mb: 2,
          color: '#6B7280',
          fontSize: '14px',
          fontWeight: 400,
          zIndex: 1,
        }}
      >
        {t('The host [host_name] indicates:', { host_name: hostName })}
      </Typography>

      <Box 
        sx={{
          width: '90%',
          backgroundColor: '#F1F5FA',
          borderRadius: '8px',
          padding: '16px',
          mb: 2,
          zIndex: 1,
        }}
      >
        <Typography 
          sx={{ 
            color: '#6F6F6F',
            fontSize: '16px',
            fontWeight: 700,
          }}
        >
          {notes}
        </Typography>
      </Box>

      <Button
        fullWidth
        onClick={() => {
          if (setNoteIsAlreadyRead) setNoteIsAlreadyRead(true);
          onClose();
        }}
        sx={{
          backgroundColor: '#6C85D3',
          color: '#FFFFFF',
          textTransform: 'none',
          borderRadius: '8px',
          padding: '8px 0',
          fontSize: '16px',
          fontWeight: 600,
          zIndex: 1,
          '&:hover': {
            backgroundColor: '#5A71BE',
          }
        }}
      >
        {t('Understood')}
      </Button>
    </Dialog>
  );
}

export default InvitationNotesDialog; 
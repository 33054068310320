import React, { useCallback } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker'
import { grey } from '@mui/material/colors'

import {
  styled,
  useTheme
} from '@mui/material/styles'

import {
  Button,
  TextField,
  Stack,
  useMediaQuery,
  CircularProgress,
  Popover,
  SwipeableDrawer,
  FormControlLabel
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import { ParsoSwitch } from '../../utilities/customMaterialUI'

import "./SwipeableCalendar.sass"

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ?
      grey[100] :
      theme.palette.background.default,
}));

function SwipeableCalendar(props) {
  const { t } = useTranslation();

  const defaultFunction = () => { }
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const {
    message = '',
    minDate,
    maxDate,
    open = false,
    clearable = false,
    multiDay = false,
    loading = false,
    enabledMultiDay = false,
    date = new Date(),
    anchorEl = window.document.body,
    setDate = defaultFunction,
    setOpen = defaultFunction,
    onChange = defaultFunction,
    onMonthChange = defaultFunction,
    onClose = props.setOpen ? () => props.setOpen(false) : defaultFunction,
    onChangeMultiDay = defaultFunction,
    onContinue,
    type = 'basic',
    renderMessage,
    renderDay
  } = props;

  const id = open ? 'simple-popover' : undefined;

  const getSwipeableCalendar = calendar => {
    return (
      <Root>
        <SwipeableDrawer
          className="swipeable-calendar"
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => setOpen(true)}
          disableSwipeToOpen={false}
        >
          {calendar}
        </SwipeableDrawer>
      </Root>
    )
  }

  const getPopoverCalendar = calendar => {
    return (
      <Popover
        className="popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {calendar}
      </Popover>
    )
  }

  const getCalendarStyle = calendar => {
    if (isLarge) return getPopoverCalendar(calendar)
    return getSwipeableCalendar(calendar)
  }

  const onChangeRangeDate = values => {
    setDate(values)
  }

  const getRangeCalendar = () => {
    return (
      <StaticDateRangePicker
        showToolbar={false}
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        clearable={clearable}
        onChange={onChangeRangeDate}
        renderInput={() => { }}
      />
    )
  }

  const getBasicCalendar = () => {
    return (
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        disabled={loading}
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        clearable={clearable}
        onMonthChange={onMonthChange}
        renderInput={params => <TextField {...params} />}
        renderDay={
          renderDay ?
            (...args) => renderDay(...args) :
            undefined
        }
      />
    )
  }

  const getTypeCalendar = type => {
    if (type === 'range') return getRangeCalendar()
    return getBasicCalendar()
  }

  const getDisabled = () => {
    return (
      loading ||
      props.isDisabled
    )
  }

  const getMultiDaySwitch = useCallback(() => {
    return (
      <div className='switch-multi-reservation-wrapper'>
        <FormControlLabel
          className='switch-multi-reservation'
          checked={multiDay}
          onChange={onChangeMultiDay}
          control={
            <ParsoSwitch
              leftlabel={t("One day")}
              rightlabel={t("Multiple days")}
            />
          }
          label=""
        />
      </div>
    )
  }, [multiDay, onChangeMultiDay, t])

  return (
    getCalendarStyle(
      <div className="SwipeableCalendar">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {enabledMultiDay && getMultiDaySwitch()}
          {getTypeCalendar(type)}
        </LocalizationProvider>
        <div className="calendar-footer">
          {renderMessage ?
            renderMessage() :
            <p className="message">{message}</p>
          }
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button
              className="footer-bottom"
              fullWidth
              variant="outlined"
              disabled={getDisabled()}
              onClick={() => onContinue ? onContinue() : setOpen(false)} >
              {loading ? (
                <CircularProgress
                  size={24}
                />
              ) : t('continue')}
            </Button>
          </Stack>
        </div>
      </div>
    )
  );
}

export default SwipeableCalendar;

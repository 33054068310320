import React from 'react';
import { Box, Button } from '@mui/material';
import MessageBox from '../MessageBox/MessageBox';

const MessageBoxWithActionButton = ({ message, actionLabel, onActionClick, ...rest }) => {
  return (
    <MessageBox
      message={
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '20px'}}>
          <Box 
            width={'80%'}
          >
            {message}
          </Box> 
          <Box 
            width={'20%'}
            display={'flex'}
            justifyContent={'flex-end'}
          >
            <Button 
              variant="outlined" 
              size="small" 
              sx={{background: '#FFF', padding: '8px', borderRadius: '8px', width: '80px', height: '32px', fontSize: '13px', ":hover": {background: '#FFF'}}}
              onClick={onActionClick}
            >
              {actionLabel}
            </Button>
          </Box>
        </Box>
      }
      {...rest}
    />
  );
};

export default MessageBoxWithActionButton;

import axios from 'axios'
import { getCurrentBrowser } from './utilities'
import { REGEX_HTTP_URL, REGEX_ENDLINE } from '../../variables'
import MobileDetect from 'mobile-detect'

const types = {
  UNDEFINED_ERROR: 'UNDEFINED_ERROR',
  GLOBAL_ERROR: 'GLOBAL_ERROR',
  API_ERROR: 'API_ERROR'
}

const clearStack = stack => {
  if (!stack) return ''

  let _stack = stack
  _stack = _stack.replace(REGEX_HTTP_URL, "")
  _stack = _stack.replace(REGEX_ENDLINE, "")
  _stack = _stack.trim()
  return _stack
}

const setLog = params => {
  const md = new MobileDetect(window.navigator.userAgent)
  const stack = clearStack(params.stack)
  const error = {
    app: 'lobby-app',
    type: params.type || types.UNDEFINED_ERROR,
    message: params.message,
    browser: getCurrentBrowser(),
    href: window.location.href,
    md_mobile: md.mobile(),
    md_phone: md.phone(),
    md_tablet: md.tablet(),
    md_userAgent: md.userAgent(),
    md_os: md.os(),
    md_is_bot: md.is('bot'),
    md_version: md.version('Webkit'),
    md_versionStr: md.versionStr('Build'),
    stack
  }

  if (params.title) error.title = params.title
  if (params.api_url) error.api_url = params.api_url
  if (params.api_method) error.api_method = params.api_method
  if (params.api_params) error.api_params = params.api_params

  const persist = JSON.parse(sessionStorage.getItem('persist:root'))
  if (persist) {
    const { profile } = JSON.parse(sessionStorage.getItem('persist:root'))
    const { email } = JSON.parse(profile)
    if (email) error.email = email
  }

  axios.get(`${window.location.origin}/logs`, { params: error })
  console.table(error)
}

export {
  types,
  setLog
}
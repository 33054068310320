import { DateTime } from 'luxon'

const MAX_MULTI_DAY_SELECTED_PARKING = 5
const REGEX_HTTP_URL = /\(https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)('')?/g
const REGEX_ENDLINE = /\n/g
// eslint-disable-next-line no-useless-escape
const REGEX_EMAIL = /[a-zA-Z0-9\-]+[\._]?[a-zA-Z0-9]+[@][\w\-]+[.]\w{2,3}/

const ONE_SECOND = 1000
const GOOGLE_MAP_URL = 'https://www.google.com/maps'
const DEFAULT_FLOOR_IMAGE = { url: 'static_map.png', size: [] }
const IMAGE_BOUNDS = [[0, 0], [1000, 1000]]
const CENTER = [0, 0]
const EIGHT_AM = DateTime.local().set({ hour: 8, minute: 0, second: 0 }).toJSDate() // Js Date 
const FIVE_PM = DateTime.local().set({ hour: 17, minute: 0, second: 0 }).toJSDate() // Js Date 

const PARKING_RESERVATION = 'ParkingReservation'
const DESK_RESERVATION = 'Workplace::DeskReservation'
const ROOM_RESERVATION = 'Workplace::RoomReservation'

const VISIT = 'VISIT'
const IMPORT = 'IMPORT'

const OFFLINE = 'OFFLINE'
const SYNCHRONIZED = 'SYNCHRONIZED'
const SYNCHRONIZING = 'SYNCHRONIZING'
const DEFAUTL_QR_START_CODE = 'parso_'

const COMES = 'COMES'
const COMPANIES = 'COMPANIES'
const DEFAULT_AUTOCOMPLETE = { id: -1, name: '' }

const DEFAULT_GUEST = {
  first_name: '',
  last_name_one: '',
  email: '',
  reason: '',
  identification: '',
  invitation_date: '',
  invitation_time: '',
  comes_from: '',
  building_id: ''
}

const DEFAULT_BLACK_LIST_USER = {
  details: '',
  first_name: '',
  identification: '',
  last_name_one: '',
  last_name_two: '',
  solutions: '',
}

export {
  DEFAUTL_QR_START_CODE,
  MAX_MULTI_DAY_SELECTED_PARKING,
  DEFAULT_BLACK_LIST_USER,
  DEFAULT_AUTOCOMPLETE,
  PARKING_RESERVATION,
  DESK_RESERVATION,
  ROOM_RESERVATION,
  DEFAULT_FLOOR_IMAGE,
  GOOGLE_MAP_URL,
  REGEX_HTTP_URL,
  REGEX_ENDLINE,
  DEFAULT_GUEST,
  IMAGE_BOUNDS,
  REGEX_EMAIL,
  ONE_SECOND,
  OFFLINE,
  SYNCHRONIZED,
  SYNCHRONIZING,
  COMES,
  COMPANIES,
  EIGHT_AM,
  FIVE_PM,
  CENTER,
  IMPORT,
  VISIT
}
import React, { useMemo } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  useMediaQuery,
  useTheme
} from "@mui/material"


import {
  WarningAmber as WarningAmberIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon
} from '@mui/icons-material'

import { defaultFunction, getFullName } from "../../utilities/utilities"

import { useTranslation } from 'react-i18next'
import { DEFAULT_BLACK_LIST_USER } from "../../../variables"

import './BlackListDialog.sass'

function BlackListDialog(props) {

  const {
    open = false,
    onClose = defaultFunction,
    user = DEFAULT_BLACK_LIST_USER,
    rejectReason = ''
  } = props

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  const fullName = useMemo(() => getFullName(user), [user])

  return (
    <Dialog
      className='blacklist-dialog'
      fullScreen={!isLarge}
      open={open}
      onClose={onClose}
      maxWidth="sm">
      <DialogContent>
        <Stack gap={3}>
          <Stack gap={1} alignItems="center" className='blacklist-header'>
            <WarningAmberIcon color='warning' />
            <h2>
              Visitante con observación:
              <br />
              {rejectReason}
            </h2>
          </Stack>
          <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={1}>
              <BadgeOutlinedIcon fontSize="small" />
              <label>Identificación: <strong>{user.identification}</strong></label>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <PersonOutlineOutlinedIcon fontSize="small" />
              <label>Nombre y apellidos: <strong>{fullName}</strong></label>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <BadgeOutlinedIcon fontSize="small" />
              <label>{t('reason')}: <strong>{rejectReason}</strong></label>
            </Stack>
            {
              user.solutions &&
              <Stack direction="row" alignItems="center" gap={1}>
                <BadgeOutlinedIcon fontSize="small" />
                <label>Solución: <strong>{user.solutions}</strong></label>
              </Stack>
            }
          </Stack>
          <Button onClick={onClose} fullWidth size='large' variant='contained'>
            Entendido
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}


export default BlackListDialog